export default {
  views: {
    list: {
      header: {
        title: "Employees",
        search_placeholder: "Search ...",
        add_button: "New employee"
      },
      table: {
        name: "Name and last name",
        position_department: "Department",
        position_title: "Title",
        created_at: "Created",
        updated_at: "Updated",
        active: "Active",
        options: "Options",
        company: "Company",
        hr_manager: "HR manager"
      }
    },
    details: {
      header: {
        back_button: "To the list of employees",
        edit_button: "Edit employee",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} employee here"
        },
        toggle_hr: {
          title: "HR manager",
          true: "Remove HR manager role for this employee.",
          false: "Add HR manager role for this employee."
        }
      },
      basic: {
        contact_info: "Contact details",
        address: "Address",
        postal: "Postal number",
        phone: "Phone",
        toggle_hr: {
          title: "HR manager",
          true: "Remove HR manager role for this employee.",
          false: "Add HR manager role for this employee."
        }
      }
    }
  },
  popup: {
    title: "New employee",
    fields: {
      name: {
        label: "Employee first and last name",
        placeholder: "Enter employee first and last name"
      },
      address: {
        label: "Address",
        placeholder: "Enter address and house number"
      },
      postal: {
        label: "Postal number",
        placeholder: "Enter postal no. and city"
      },
      country: {
        label: "Country",
        placeholder: "Enter country"
      },
      phone: {
        label: "Phone number",
        placeholder: "Enter phone number"
      },
      position_title: {
        label: "Title",
        placeholder: "Enter title"
      },
      position_department: {
        label: "Department",
        placeholder: "Enter department"
      },
      email: {
        label: "E-mail",
        placeholder: "Enter e-mail"
      },
      company: {
        label: "Company",
        search_not_found: "Company not found"
      },
      hr_manager: {
        label: "HR manager",
        true: "User will be assigned a HR manager role. Client portal will permit him/her additional functionalities.",
        false: "User will not be assigned a HR manager role. Client portal will not grant him/her all functionalities."
      }
    },
    add_button: "Save employee",
    update_button: "Save employee"
  },
  prompts: {
    delete: {
      title: "Delete employee {name}",
      message: "Are you certain you wish to delete employee {name}?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Employee {name} successfully saved",
    updated: "Employee {name} successfully updated",
    deleted: "Employee {name} successfully deleted",
    not_found: "Employee not found"
  }
}
