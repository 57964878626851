export default {
  views: {
    list: {
      headline: {
        open: "Open contracts",
        closed: "Closed contracts"
      },
      empty_list_notice: {
        none: "No open contracts",
        closed: "All contracts are closed"
      },
      card: {
        status: {
          "preparation": "In preparation",
          "coordination": "Coordinating agreement",
          "signing": "Put in signing",
          "signed": "Signed"
        },
        button_text: {
          signed: "View contract",
          signing: "Sign contract",
          else: "Open contract"
        },
        created_at: "Created",
        updated_at: "Last change",
        finished_at: "Finished",
        hiring_manager: "Hiring manager",
        signer: "Signee",
        action_needed: "Pending confirmation",
        action_done: "Confirmed"
      },
      header: {
        title: "Contracts",
        search_placeholder: "Search ..."
      },
      table: {
        title: "Title",
        status: "Status",
        created_at: "Created",
        updated_at: "Updated",
        active: "Active",
        options: "Options",
        type: "Contract type"
      }
    },
    edit: {
      title: "New contract",
      back_button: "To list of contracts",
      create_project: "Open new project",
      create_from_offer: "Create from contract",
      tabs: {
        overview: "Overview",
        contacts: "Contacts"
      },
      footer: {
        add_button: "Add contract",
        save_button: "Save contract"
      },
      overview: {
        title: "Title",
        company: "Company",
        work_field: "Job position",
        service: "Service",
        candidates_count: "Number of candidates",
        warranty: "Warranty period (no. months)",
        payment_type: "Payment structure",
        payment_deadline: "Payment due (no. days)",
        execution_deadline: "Delivery date (no. days)",
        fee: {
          fix: "Fixed fee in EUR",
          percent: "% of gross salary"
        },
        fee_lump: "Flat-rate due after signing",
        fee_interim: "Intermediate fee due after presenting candidates",
        fee_final: "Final fee",
        eur: " (EUR)",
        responsible: "Responsible for contract",
        hiring_manager: "Hiring manager",
        signer: "Signee",
        user_not_found: "User not found",
        company_not_found: "Company does not exist",
        salary: "Fee",
        installment: "Instalments",
        created_at: "Created",
        updated_at: "Updated",
        invoice_number: "Purchase no. if required on invoice",
        invoice_note: "Additional remarks to be added on invoice",
        type: "Contract type"
      },
      contacts: {
        edit_button: "Edit contacts",
        contacts_popup: {
          save_button: "Save",
          title: "Edit contacts"
        }
      },
      sidebar: {
        activity: "Activity",
        create: " has created a contract ",
        status_change: " has changed status to ",
        signature: " has signed the contract",
        upload: " has uploaded PDF contract",
        approval: " has approved status change to ",
        edit_document: "Edit contract",
        status_select: "Contract status",
        file_select: "File shown",
        buttons: {
          download_word: "Download Word file",
          upload_pdf: "Upload PDF file",
          download_pdf: "Download PDF file",
          approve: {
            preparation: "I am ready to start coordinating contract",
            coordination: "I am finished coordinating contract",
            signing: "I have uploaded signed contract"
          }
        },
        pdf_doesnt_exist: "Upload signed contract",
        download_word_file: "Download contract to desktop",
        prompts: {
          edit_file: {
            title: "Edit Word file",
            message1: "The file will be opened in Microsoft Word, where you can edit it. When you finish editing, the file must be saved. The changes will be automatically visible in the portal.",
            message2: "When opening the file, Microsoft Word will ask for your credentials. Enter the information provided below.",
            username: "User name",
            password: "Password",
            confirm: "Edit file",
            cancel: "Cancel"
          }
        }
      },
      preview: {
        preparing_document: "Your document is getting ready"
      },
      status: {
        "preparation": "In preparation",
        "coordination": "In coordination",
        "signing": "In signing",
        "signed": "Signed"
      }
    }
  },
  notify: {
    stored: "Contract has been saved {title}",
    updated: "Contract has been updated {title}",
    deleted: "Contract {title} was deleted",
    not_found: "Contract not found"
  }
}
