export default {
  views: {
    list: {
      headline: "Vaša opravila",
      empty_list_notice: "Odlično. Vaš seznam opravil je prazen.",
      open_projects: "Odprti projekti",
      open_contracts: "Odprte pogodbe",
      open_questionnaires: "Odprti vprašalniki"
    }
  }
}
