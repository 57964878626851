export default {
  views: {
    list: {
      headline: {
        open: "Odprte pogodbe",
        closed: "Zaključene pogodbe"
      },
      empty_list_notice: {
        none: "Ni odprtih pogodb",
        closed: "Vse pogodbe so zaključene"
      },
      card: {
        status: {
          "preparation": "V pripravi",
          "coordination": "Usklajevanje",
          "signing": "Podpisovanje",
          "signed": "Podpisana"
        },
        button_text: {
          signed: "Ogled pogodbe",
          signing: "Podpiši pogodbo",
          else: "Odpri pogodbo"
        },
        created_at: "Ustvarjeno",
        updated_at: "Zadnja sprememba",
        finished_at: "Zaključeno",
        hiring_manager: "Vodja zaposlovanja",
        signer: "Podpisnik",
        action_needed: "Potrebna potrditev",
        action_done: "Potrjeno"
      },
      header: {
        title: "Pogodbe",
        search_placeholder: "Iskanje ..."
      },
      table: {
        title: "Naziv",
        status: "Status",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktivna",
        options: "Možnosti",
        type: "Tip pogodbe"
      }
    },
    edit: {
      title: "Nova pogodba",
      back_button: "Na listo pogodb",
      create_project: "Ustvari projekt",
      create_from_offer: "Ustvari iz ponudbe",
      tabs: {
        overview: "Pregled",
        contacts: "Kontakti"
      },
      footer: {
        add_button: "Dodaj pogodbo",
        save_button: "Shrani pogodbo"
      },
      overview: {
        title: "Naziv",
        company: "Podjetje",
        work_field: "Delovno mesto",
        service: "Storitev",
        candidates_count: "Število kandidatov",
        warranty: "Garancijsko obdobje (št. mesecev)",
        payment_type: "Način obračunavanja honorarja",
        payment_deadline: "Rok plačila (št. dni)",
        execution_deadline: "Rok izvedbe (št. dni)",
        fee: {
          fix: "Fiksni znesek v EUR",
          percent: "% bruto plače"
        },
        fee_lump: "Pavšalni znesek ob podpisu",
        fee_interim: "Vmesni honorar",
        fee_final: "Končni honorar",
        eur: " (EUR)",
        responsible: "Odgovorna oseba",
        hiring_manager: "Vodja zaposlovanja",
        signer: "Podpisnik",
        user_not_found: "Oseba ne obstaja",
        company_not_found: "Podjetje ne obstaja",
        salary: "Honorar",
        installment: "Obroki",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        invoice_number: "Št. naročilnice na fakturi",
        invoice_note: "Dodatna opomba na fakturi",
        type: "Tip pogodbe"
      },
      contacts: {
        edit_button: "Uredi kontakte",
        contacts_popup: {
          save_button: "Shrani",
          title: "Uredi kontakte"
        }
      },
      sidebar: {
        activity: "Aktivnost",
        create: " je ustvaril pogodbo ",
        status_change: " je spremenil status v ",
        signature: " je podpisal pogodbo",
        upload: " je naložil PDF pogodbe",
        approval: " je odobril spremembo statusa v ",
        edit_document: "Uredi pogodbo",
        status_select: "Status pogodbe",
        file_select: "Prikazana datoteka",
        buttons: {
          download_word: "Prenesi Word datoteko",
          upload_pdf: "Naloži PDF datoteko",
          download_pdf: "Prenesi PDF datoteko",
          approve: {
            preparation: "Pripravljen sem na usklajevanje pogodbe",
            coordination: "Zaključil sem z usklajevanjem",
            signing: "Naložil sem podpisano pogodbo"
          }
        },
        pdf_doesnt_exist: "Naloži podpisano pogodbo",
        download_word_file: "Prenesi pogodbo na namizje",
        prompts: {
          edit_file: {
            title: "Urejanje Word datoteke",
            message1: "Datoteka bo odprta v programu Microsoft Word, kjer jo lahko urejate. Ob zaključku urejanja je datoteko potrebno shraniti. Spremembe bodo samodejno vidne v portalu.",
            message2: "Ob odprtju datoteke vas bo Microsoft Word vprašal za prijavne podatke. Vpišite podatke, ki so navedeni spodaj.",
            username: "Uporabniško ime",
            password: "Geslo",
            confirm: "Uredi datoteko",
            cancel: "Prekliči"
          }
        }
      },
      preview: {
        preparing_document: "Pripravljamo vaš dokument"
      },
      status: {
        "preparation": "V pripravi",
        "coordination": "Usklajevanje",
        "signing": "Podpisovanje",
        "signed": "Podpisana"
      }
    }
  },
  notify: {
    stored: "Uspešno ste shranili pogodbo {title}",
    updated: "Uspešno ste posodobili pogodbo {title}",
    deleted: "Pogodba {title} je bila izbrisana",
    not_found: "Pogodba ni bila najdena"
  }
}
