export default {
  views: {
    list: {
      header: {
        title: "Testiranja",
        search_placeholder: "Iskanje ..."
      },
      table: {
        title: "Naziv",
        status: "Stanje",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        options: "Možnosti"
      }
    },
    details: {
      header: {
        back_button: "Na listo testiranj"
      },
      basic: {
        responsible: "Odgovorna oseba",
        take_over: "Prevzemi testiranje",
        project: "Projekt",
        consent: "Tip soglasja",
        deadline: "Zaključek testiranja",
        created: "Ustvarjeno",
        updated: "Posodobljeno",
        status: "Stanje",
        comment: "Opomba"
      },
      competences: {
        headline: "Splošne kompetence",
        headline_description: "Splošne kompetence testiranja"
      },
      stats: {
        headline: "Stanje testiranja",
        headline_description: "Število opravljenih vprašalnikov",
        complete: "Opravljeno",
        buttons: {
          testing_is_closed: "Testiranje zaključeno"
        }
      }
    }
  }
}
