export default {
  views: {
    list: {
      header: {
        title: "Tests",
        search_placeholder: "Search ..."
      },
      table: {
        title: "Title",
        status: "Status",
        created_at: "Created",
        updated_at: "Updated",
        options: "Options"
      }
    },
    details: {
      header: {
        back_button: "To the list of tests"
      },
      basic: {
        responsible: "Person responsible",
        take_over: "Adopt test",
        project: "Project",
        consent: "Type of consent",
        deadline: "Test due date",
        created: "Created",
        updated: "Updated",
        status: "Status",
        comment: "Remark"
      },
      competences: {
        headline: "General competences",
        headline_description: "Test general competences"
      },
      stats: {
        headline: "Test status",
        headline_description: "Number of solved questionnaires",
        complete: "Completed",
        buttons: {
          testing_is_closed: "Test completed"
        }
      }
    }
  }
}
