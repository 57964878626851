export default {
  views: {
    list: {
      header: {
        title: "Meritve",
        search_placeholder: "Iskanje ..."
      },
      table: {
        title: "Naziv",
        status: "Stanje",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        type: "Tip meritve"
      },
      type: {
        climate: "Klima",
        culture: "Kultura",
        commitment: "Zavzetost",
        "360": "360"
      }
    },
    details: {
      header: {
        back_button: "Na listo merjenj"
      },
      basic: {
        project: "Projekt",
        measure_type: "Tip meritve",
        measure_360: "Meritev 360",
        questionnaire: "Pripet vprašalnik",
        deadline_at: "Zaključek meritve",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        comment: "Opomba",
        type: {
          climate: "Klima",
          culture: "Kultura",
          commitment: "Zavzetost",
          "360": "360"
        }
      },
      stats: {
        status: "Stanje meritve",
        questionnaires: "Število opravljenih vprašalnikov",
        measure_closed: "Merjenje zaključeno",
        finished: "Opravljeno"
      }
    }
  }
}
