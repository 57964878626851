export default {
  views: {
    details: {
      candidate_settings: {
        headline: {
          basic: "Osebni podatki",
          other: "Ostali podatki"
        },
        headline_description: "Uporabniški podatki",
        headline_description_2: "Ostali podatki vezani na uporabnika",
        fields: {
          name: {
            label: "Ime in priimek"
          },
          gender: {
            label: "Spol"
          },
          education: {
            label: "Stopnja izobrazbe"
          },
          birthday: {
            label: "Datum rojstva"
          },
          email: {
            label: "Elektonski naslov",
            placeholder: "info@email.com"
          },
          address: {
            label: "Hišni naslov"
          },
          postal: {
            label: "Poštni naslov"
          },
          country: {
            label: "Država"
          },
          phone: {
            label: "Telefonska številka"
          },
          position_title: {
            label: "Naziv pozicije"
          },
          position_department: {
            label: "Naziv oddelka"
          },
          username: {
            label: "Uporabniško ime"
          }
        },
        gender: {
          male: "Moški",
          female: "Ženska"
        },
        button: {
          save: "Shrani podatke"
        }
      },
      reset_password: {
        title: "Sprememba gesla",
        subtitle: "Varnost in prijava",
        form: {
          currentPassword: "Trenutno geslo",
          newPassword: "Novo geslo",
          changePassword: "Spremeni geslo",
          passwordRule: "Geslo mora vsebovati najmanj 6 znakov, vsaj eno črko, številko in poseben znak"
        },
        feedback: {
          succcess: "Vaše geslo je bilo uspešno spremenjeno!",
          changeItAgain: "Ponovno spremeni?"
        }
      },
      language: {
        title: "Spremeni jezik"
      }
    },
    user_info: {
      headline: "Pozdravljeni v Competo Portalu",
      headline_description: "Vaš profil je skoraj pripravljen",
      p1: "Ustvarili smo vaš uporabniški račun, le še nekaj podatkov nam manjka.",
      p2: "Preden nadaljujete vas prosimo, da navedete manjkajoče podatke in preverite verodostojnost obstojočih.",
      p3: "Podatke lahko kadarkoli spremenite v vaših uporabniških nastavitvah."
    }
  }
}
