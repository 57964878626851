// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  // {
  //   path: "/administration/codelists",
  //   name: "codeLists",
  //   meta: { title: "Codelists" },
  //   component: () => import(/* webpackChunkName: "codelists" */ "@/Modules/Codelist/Views/List.vue")
  //   // beforeEnter: ifAuthenticated
  // }
]
