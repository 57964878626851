export default {
  views: {
    details: {
      contacts: {
        edit_button: "Uredi kontakte",
        contacts_popup: {
          save_button: "Shrani"
        }
      },
      basic: {
        tax_code: "Davčna št."
      }
    }
  }
}
