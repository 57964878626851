// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  // {
  //   path: "/administration/roles",
  //   name: "Roles",
  //   meta: { title: "Roles" },
  //   component: () => import(/* webpackChunkName: "Roles List" */ "@/Modules/Auth/Role/Views/List"),
  //   children: [
  //     {
  //       path: "new",
  //       name: "New Role",
  //       meta: { title: "New Role" }
  //     },
  //     {
  //       path: "edit/:id",
  //       name: "Edit Role (List)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: "/administration/roles/details/:id",
  //   name: "Role Details",
  //   component: () => import(/* webpackChunkName: "Role Details" */ "@/Modules/Auth/Role/Views/Details"),
  //   children: [
  //     {
  //       path: "edit",
  //       name: "Edit Role (Details)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // }
]
