<template>
    <div class="cookie-notice">
        <transition name="cookie-notice-notice">
            <div
                v-if="showNotice"
                class="cookie-notice__wrapper">
                <div class="cookie-notice__title">{{ $t('Global.components.cookies.title') }}</div>
                <!-- <button class="cookie-notice__close">
        <Icon :icon="['fal', 'times']"/>
      </button> -->
                <p class="cookie-notice__text">{{ $t('Global.components.cookies.text') }}</p>
                <Button
                    :text="$t('Global.components.cookies.button')"
                    class="cookie-notice__button"
                    small
                    color="orange"
                    @click="hideNotice()"
                />
            </div>
        </transition>
        <transition name="cookie-notice-cookie">
            <Cookie v-if="showNotice"/>
        </transition>
    </div>
</template>

<script>
import Button from "@/Components/Button"
import Cookie from "./components/Cookie"
export default {
  components: { Button, Cookie },
  data () {
    return {
      showNotice: false
    }
  },
  created () {
    this.showNotice = !this.getCookie("cookieNotice")
  },
  methods: {
    hideNotice () {
      this.showNotice = false
      this.setCookie("cookieNotice", true, 365)
    },
    setCookie (name, value, days) {
      let expires = ""
      if (days) {
        let date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = "; expires=" + date.toUTCString()
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/"
    },
    getCookie (name) {
      let nameEQ = name + "="
      let ca = document.cookie.split(";")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    },
    removeCookie (name) {
      document.cookie =
        name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
    }
  }
}
</script>

<style lang="scss">
.cookie-notice {
  position: fixed;
  right: 20px;
  bottom: 0;
  width: calc(100% - 40px);
  max-width: 500px;
  z-index: 2;
  .cookie-notice__wrapper {
    background: white;
    box-shadow: 0 5px 15px rgba($textMain, 0.15);
    border-radius: 15px;
    padding: 20px 25px;
    margin-bottom: 25px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    animation: cookie-notice 0.5s 2.5s backwards;
    @keyframes cookie-notice {
      from {
        pointer-events: none;
        transform: scale(0);
      }
      to {
        transform: none;
        pointer-events: initial;
      }
    }
    .cookie-notice__title {
      font-size: 18px;
      font-weight: 600;
      color: $textMain;
      margin-bottom: 5px;
    }
    .cookie-notice__text {
      font-size: 16px;
      line-height: 1.35;
      font-weight: 400;
      color: rgba($textMain, 0.9);
    }
    .cookie-notice__close {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      border: none;
      background: white;
      box-shadow: $boxShadow;
      border-radius: 50%;
      width: 33px;
      height: 33px;
      position: absolute;
      top: -6px;
      right: -6px;
      outline: none;
      transition: all 0.18s;
      svg {
        color: $textBase;
        font-size: 18px;
        transition: all 0.18s;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
        svg {
          color: $red;
        }
      }
      &:active {
        transform: none;
      }
    }
    .cookie-notice__button {
      position: absolute;
      top: -16px;
      right: 16px;
      height: 37px;
      padding: 0 18px;
      background: $orange;
      box-shadow: 0 5px 15px -5px rgba($orange, 0.75);
      font-size: 14px;
      &:hover {
        background: $red;
        box-shadow: 0 5px 15px -5px $red;
      }
    }
  }
  @media only #{$mediaMobile} {
    right: 10px;
    width: calc(100% - 20px);
    .cookie-notice__wrapper {
      margin-bottom: 10px;
      padding: 17px 22px;
      .cookie-notice__title {
        margin-bottom: 4px;
      }
      .cookie-notice__text {
        font-size: 15px;
        color: $textMain;
      }
      .cookie-notice__button {
        right: 7px;
      }
    }
  }
}
.cookie-notice-notice-leave-active,
.cookie-notice-cookie-leave-active .cookie__wrapper .cookie__trick {
  transition: all 0.35s;
}
.cookie-notice-notice-leave-to {
  transform: scale(0);
}
.cookie-notice-cookie-leave-to {
  .cookie__wrapper {
    .cookie__trick {
      transform: translateY(50px);
    }
  }
}
</style>
