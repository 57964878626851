export default {
  views: {
    list: {
      headline: {
        open: "Open questionnaires",
        closed: "Closed questionnaires"
      },
      empty_list_notice: {
        none: "No open questionnaires",
        closed: "All questionnaires are closed"
      },
      card: {
        status: {
          open: "Open",
          "in-progress": "In progress",
          closed: "Closed"
        },
        header: {
          tag: {
            outgoing: "External"
          }
        },
        scope: {
          assessed_person: "Assessed person",
          self: "Self-assessment"
        },
        stats: {
          completion_time: "Estimated time to finish",
          questions_count: "No. of questiones"
        },
        created_at: "Created",
        updated_at: "Last change",
        finished_at: "Finished",
        outgoing_notice: {
          title: "You are leaving Competo portal",
          p1: "Test you are about to take is accessable on external web address. We are opening a new tab which will reroute you to an extenal web page.",
          p2: {
            part1: "Once a questionnaire is opened, it must be completed in one go.",
            part2: "Results may not be accessable right away. It may take up to 1h for external partner to send results to our system"
          },
          p3: "Your browser settings may block opening links in new tabs. If pressing the button below does not result in any action, please access your browser settings and enable opening links in new tabs.",
          button: {
            close: "Close",
            continue: "Continue with answering questionnaire"
          }
        },
        button_text: {
          "in-progress": "Continue with answering questionnaire",
          closed: "View questionnaire",
          else: "Start answering"
        }
      }
    },
    details: {
      header: {
        headline: "Questionnaire",
        tooltip: "Change view",
        duration: "Duration",
        scope: {
          assessed_person: "Assessed person",
          self: "Self-assessment"
        }
      },
      completed: {
        p1: "You have succesfully finished",
        p2: "Thank you for your cooperation. Have a great day!"
      },
      content: {
        components: {
          answer: {
            add_row: "Add line",
            sum: "Sum",
            cant_edit: "Changing answers on closed questionnaires is not permitted"
          }
        }
      },
      slides: {
        navigation: {
          back: "Back",
          close: "Close",
          end: "End",
          continue: "Continue"
        }
      },
      full: {
        content: {
          close: "Close questionnaire",
          end: "End"
        }
      },
      questionnaire_360: {
        headline: "Questionnaire will ask you to give feedback on {name}. Please define your position in terms of your company's organizational hierarchy.",
        self: "Self-assessment - this is me",
        leader: "Head - I am his/her manager",
        coworker: "Peer - we work on the same hierarchal level (Co-workers)",
        employee: "Subordinate - I report to this person"
      }
    }
  }
}
