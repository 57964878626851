<template>
    <div class="cookie">
        <div
            class="cookie__wrapper"
            @click="triggerTrick()"
        >
            <div
                :class="{'cookie__trick--active': jump}"
                class="cookie__trick"
            >
                <div class="cookie__trick-roll">
                    <div class="cookie__container">
                        <Cookie class="cookie__cookie"/>
                        <Eyes class="cookie__eyes"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cookie from "./../Assets/Cookie"
import Eyes from "./../Assets/Eyes"
export default {
  components: { Cookie, Eyes },
  data () {
    return {
      jump: false
    }
  },
  methods: {
    triggerTrick () {
      if (!this.jump) {
        this.jump = true
        setTimeout(() => {
          this.jump = false
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss">
.cookie {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  bottom: -15px;
  width: 100%;
  animation: cookie-appear 1.5s 1s both;
  @keyframes cookie-appear {
    0% {
      transform: translate(25px, 50px);
    }
    20% {
      transform: none;
    }
    100% {
      transform: translateX(-100px);
    }
  }
  @media only #{$mediaMobile} {
    animation: cookie-appear-mobile 1.5s 1s both;
    @keyframes cookie-appear-mobile {
      0% {
        transform: translate(25px, 50px);
      }
      20% {
        transform: none;
      }
      100% {
        transform: translateX(-75px);
      }
    }
  }
  .cookie__wrapper {
    cursor: pointer;
    animation: cookie-jump 1s infinite 2.5s ease-in-out;
    @keyframes cookie-jump {
      from,
      to {
        transform: none;
      }
      50% {
        transform: translateY(-10px);
      }
    }
    .cookie__trick {
      &--active {
        animation: cookie-trick-jump 1s;
        @keyframes cookie-trick-jump {
          from,
          to {
            transform: none;
          }
          50% {
            transform: translateY(-100px);
          }
        }
        .cookie__trick-roll {
          animation: cookie-trick-roll 1s;
          @keyframes cookie-trick-roll {
            to {
              transform: rotate(720deg);
            }
          }
        }
      }
    }
    .cookie__container {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      width: 50px;
      height: 50px;
      animation: cookie-roll 1.5s 1s both;
      @keyframes cookie-roll {
        from,
        20% {
          transform: none;
        }
        to {
          transform: rotate(-720deg);
        }
      }
      > * {
        width: 100%;
        position: absolute;
      }
      .cookie__eyes {
        animation: cookie-eyes 0.5s 2s both;
        @keyframes cookie-eyes {
          from {
            transform: none;
          }
          to {
            transform: translate(-2px, -4px);
          }
        }
      }
    }
  }
}
</style>
