export default {
  views: {
    form: {
      slides: {
        navigation: {
          back: "Nazaj",
          conclude: "Zaključi",
          continue: "Nadaljuj"
        },
        content: {
          heading: {
            page1: {
              1: "Pozdravljeni v portalu za informativne ponudbe podjetja Competo.",
              2: "Za začetek postopka prosimo vpišite vaše podatke.",
              3: "Vsi podatki so obvezni ter predstavljajo pogoj za nadaljevanje."
            },
            page2: {
              1: "V podjetju ponujamo več storitev. Za nadaljevanje postopka izberite eno."
            },
            page3: {
              workshops: "Na seznamu obkljukajte delavnice, ki vas zanimajo in za izbrane delavnice vnesite zahtevane informacije."
            }
          },
          type_select: {
            project: "Iskanje in selekcija",
            testing: "Psihološko testiranje",
            climate: "Klima",
            ratingcenter: "Ocenjevalni center",
            measure360: "Meritev 360",
            teambuilding: "Teambuilding",
            coaching: "Coaching",
            workshops: "Delavnice",
            leadershipschool: "Šola vodenja"
          },
          fields: {
            company: {
              label: "Podjetje"
            },
            contact: {
              label: "Kontaktna oseba"
            },
            title: {
              label: "Naziv"
            },
            email: {
              label: "Elektronski naslov"
            },
            phone: {
              label: "Telefonska številka"
            }
          },
          questions: {
            label: "Vprašanje",
            project: {
              label: "Naziv delovnega mesta, ki ga iščete (opisno)"
            },
            testing: {
              label: "Delovno mesto (opisno)"
            },
            climate: {
              label: "Ali imajo vsi zaposleni službene email naslove?"
            },
            measure360: {
              label: "Ali imajo vsi zaposleni službene email naslove?"
            },
            teambuilding: {
              label: "Število dni?"
            }
          },
          level: {
            label: "Nivo",
            top: "Top management",
            middle: "Middle management",
            professional: "Strokovno",
            leading: "Vodstveno"
          },
          email_boolean: "Vsi zaposleni imajo službene email naslove.",
          participants: "Število udeležencev",
          duration: {
            label: "Število dni"
          },
          workshop: {
            duration: {
              label: "Trajanje delavnice",
              4: "4 ure",
              6: "6 ur",
              8: "8 ur"
            },
            motivation: "Motivacija in samomotivacija",
            cooperation: "Učinkovito sodelovanje",
            teamwork: "Timsko delo",
            communication: "Temelji učikovite komunikacije",
            leadership: "Učinkovito vodenje",
            stress: "Upravljanje stresa",
            emotions: "Čustvena inteligentnost",
            balance: "Obnova energije in uravnoteženost",
            feedback: "Povratna informacija"
          }
        }
      },
      completed: {
        p1: "Uspešno ste oddali povpraševanje za storitev",
        p2: "Za oddajo dodatne ponudbe kliknite na spodnji gumb.",
        new_offer: "Nova ponudba"
      }
    }
  }
}
