export default {
  user: {
    views: {
      list: {
        header: {
          title: "Users",
          search_placeholder: "Search ...",
          add_button: "Add user"
        },
        table: {
          title: "Title",
          created_at: "Created at",
          updated_at: "Updated at",
          author: "Author",
          last_modified: "Last modified",
          active: "Active"
        }
      },
      details: {
        header: {
          back_button: "Users list",
          edit_button: "Edit user",
          toggle: {
            title: "Active",
            activate: "activate",
            deactivate: "deactivate",
            description: "You can {action} user here"
          }
        },
        main: {
          active: "Active",
          inactive: "Inactive",
          overview: {
            author: "Author",
            created_at: "Created at",
            updated_at: "Updated at",
            last_modified: "Last modified"
          }
        },
        resetPassword: {
          title: "Change Password",
          subtitle: "Security and authentication",
          form: {
            currentPassword: "Current password",
            newPassword: "New password",
            changePassword: "Change password",
            passwordRule: "Password has to be at least 6 characters long and contain a letter, a number and a symbol"
          },
          feedback: {
            succcess: "You have successfully changed users password.",
            changeItAgain: "change it again?"
          }
        }
      }
    },
    popup: {
      title: "Nov uporabnik",
      fields: {
        name: "Ime in priimek uporabnika",
        username: "Uporabniško ime",
        email: "Elektronski naslov",
        active: {
          description: "Aktivnemu uporabniku ne po treba potrditi svoj elektronski naslov.",
          label: "Aktiven uporabnik"
        }
      },
      add_button: "Shrani uporabnika",
      update_button: "Shrani uporabnika"
    },
    prompts: {
      delete: {
        title: "Izbris uporabnika {name}?",
        message: "Ste prepričani da želite izbrisati uporabnika {name}?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uspešno ste shranili uporabnika {name}",
      updated: "Uspešno ste posodobili uporabnika {name}",
      deleted: "Uspešno ste izbrisali uporabnika {name}",
      not_found: "Uporabnik ni bil najden"
    }
  },
  permissions: {
    title: "Permissions",
    add_button: "Add permissions",
    table: {
      columns: {
        permission: "Permission",
        module: "Module"
      },
      remove_button: "Remove permission"
    },
    empty_notice: "There are no permissions for {name}.",
    add_drawer: {
      title: "Add permissions to {name}",
      search_placeholder: "Search ...",
      permissions_count: "{count} selected",
      table: {
        columns: {
          permission: "Permission",
          module: "Module"
        }
      },
      cancel_button: "Cancel",
      confirm_button: "Add selected permissions",
      notify: {
        success: "{count} permissions added to {name}",
        error: "An error occured while adding permissions to {name}"
      }
    }
  },
  access_drawer: {
    title: "Upravljanje uporabnikov",
    search: "Iskanje ...",
    tag: "Število uporabnikov: ",
    table: {
      name: "Ime in priimek"
    },
    cancel_button: "Prekliči",
    save_button: "Shrani"
  },
  one_user_widget: {
    title: "Uporabniški račun",
    email: "Elektronski naslov",
    username: "Uporabniško ime",
    name: "Ime in priimek uporabnika",
    edit_button: "Uredi",
    new_button: "Ustvari",
    redirect: "Ogled uporabnika",
    no_user: "Uporabnik nima uporabniškega računa. Za ustvarjanje računa kontaktirajte Competo."
  }
}
