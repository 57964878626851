export default {
  views: {
    details: {
      candidate_settings: {
        headline: {
          basic: "Personal data",
          other: "Other data"
        },
        headline_description: "User data",
        headline_description_2: "Other data linked to user",
        fields: {
          name: {
            label: "Name and last name"
          },
          gender: {
            label: "Sex"
          },
          education: {
            label: "Level of education"
          },
          birthday: {
            label: "Date of Birth"
          },
          email: {
            label: "E-mail",
            placeholder: "info@email.com"
          },
          address: {
            label: "House address"
          },
          postal: {
            label: "Postal address"
          },
          country: {
            label: "Country"
          },
          phone: {
            label: "Phone"
          },
          position_title: {
            label: "Job title"
          },
          position_department: {
            label: "Department"
          },
          username: {
            label: "Username"
          }
        },
        gender: {
          male: "Male",
          female: "Female"
        },
        button: {
          save: "Save data"
        }
      },
      reset_password: {
        title: "Update password",
        subtitle: "Security and login",
        form: {
          currentPassword: "Current password",
          newPassword: "New password",
          changePassword: "Change password",
          passwordRule: "Password must contain at least 6 characters, one letter, number and special character."
        },
        feedback: {
          succcess: "Your password is reset!",
          changeItAgain: "Reset again?"
        }
      },
      language: {
        title: "Change language"
      }
    },
    user_info: {
      headline: "Welcome to Competo Portal",
      headline_description: "Your profile is almost ready",
      p1: "We have created your user account, we only need a few more details.",
      p2: "Before proceeding please enter missing data and check if all entered data is correct.",
      p3: "You can always change your entries in your user settings."
    }
  }
}
