export default {
  views: {
    form: {
      slides: {
        navigation: {
          back: "Back",
          conclude: "End",
          continue: "Continue"
        },
        content: {
          heading: {
            page1: {
              1: "Welcome to Competo portal for informative quotes.",
              2: "To begin please enter your data.",
              3: "All fields are mandatory to continue."
            },
            page2: {
              1: "Competo offers multiple services. To continue please pick one."
            },
            page3: {
              workshops: "From the list pick workshops you are interested in and enter required information."
            }
          },
          type_select: {
            project: "Search and selection",
            testing: "Psychological testing",
            climate: "Climate evaluation",
            ratingcenter: "Assesment center",
            measure360: "Evaluation 360",
            teambuilding: "Teambuilding",
            coaching: "Coaching",
            workshops: "Workshops",
            leadershipschool: "Leadership academy"
          },
          fields: {
            company: {
              label: "Company"
            },
            contact: {
              label: "Contact person"
            },
            title: {
              label: "Title"
            },
            email: {
              label: "Email"
            },
            phone: {
              label: "Phone number"
            }
          },
          questions: {
            label: "Question",
            project: {
              label: "Title of the position you are serching for"
            },
            testing: {
              label: "Position"
            },
            climate: {
              label: "Do all employees have an email addresses?"
            },
            measure360: {
              label: "Do all employees have an email addresses?"
            },
            teambuilding: {
              label: "Number of days?"
            }
          },
          level: {
            label: "Level",
            top: "Top management",
            middle: "Middle management",
            professional: "Professional",
            leading: "Managerial"
          },
          email_boolean: "All employees have an email addresses.",
          participants: "Number of participants",
          duration: {
            label: "Number of days"
          },
          workshop: {
            duration: {
              label: "Workshop duration",
              4: "4 hours",
              6: "6 hours",
              8: "8 hours"
            },
            motivation: "Motivation and intrinsic motivation",
            cooperation: "High performance cooperation",
            teamwork: "Team work",
            communication: "Highly efficent communication",
            leadership: "Effective leadership",
            stress: "Stress management",
            emotions: "Emotional inteligence",
            balance: "Sustaining self-balance",
            feedback: "Feedback"
          }
        }
      },
      completed: {
        p1: "You have successfully submitted a quote for the service: ",
        p2: "To request additional quote please click button bellow.",
        new_offer: "New quote"
      }
    }
  }
}
