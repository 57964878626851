export default {
  views: {
    list: {
      header: {
        title: "Fokusne skupine",
        search_placeholder: "Iskanje ..."
      },
      table: {
        title: "Naziv",
        created_at: "Ustvarjeno"
      }
    }
  }
}
