// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"
export default [
  // {
  //   path: "/administration/users",
  //   name: "Users",
  //   meta: { title: "Users" },
  //   component: () => import(/* webpackChunkName: "Users List" */ "@/Modules/Auth/User/Views/List"),
  //   children: [
  //     {
  //       path: "new",
  //       name: "New User",
  //       meta: { title: "New User" }
  //     },
  //     {
  //       path: "edit/:id",
  //       name: "Edit User (List)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: "/administration/users/details/:id",
  //   name: "User Details",
  //   component: () => import(/* webpackChunkName: "User Details" */ "@/Modules/Auth/User/Views/Details"),
  //   children: [
  //     {
  //       path: "edit",
  //       name: "Edit User (Details)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // }
]
