export default {
  views: {
    list: {
      headline: "Your to do list",
      empty_list_notice: "Great. Your to do list is empty.",
      open_projects: "Open projects",
      open_contracts: "Open contracts",
      open_questionnaires: "Open questionnaires"
    }
  }
}
