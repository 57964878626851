export default {
  views: {
    list: {
      headline: {
        open: "Odprti vprašalniki",
        closed: "Zaključeni vprašalniki"
      },
      empty_list_notice: {
        none: "Ni odprtih vprašalnikov",
        closed: "Vsi vprašalniki so zaključeni"
      },
      card: {
        status: {
          open: "Odprt",
          "in-progress": "V izvajanju",
          closed: "Zaprt"
        },
        header: {
          tag: {
            outgoing: "Zunanji"
          }
        },
        scope: {
          assessed_person: "Ocenjevana oseba",
          self: "samoocena"
        },
        stats: {
          completion_time: "Predviden čas reševanja",
          questions_count: "Število vprašanj"
        },
        created_at: "Ustvarjeno",
        updated_at: "Zadnja sprememba",
        finished_at: "Zaključeno",
        outgoing_notice: {
          title: "Zapuščate portal",
          p1: "Test ki ga odpirate je dostopen na tujem naslovu. V novem zavihku vas bomo preusmerili na stran zunaj Portala.",
          p2: {
            part1: "Ko odprete povezavo do vprašalnika, ga morate izpolniti v celoti.",
            part2: "Rezultati morda ne bodo vidni takoj."
          },
          p3: "Brskalnik občasno prepreči odpiranje povezav v novih zavihkih. V kolikor se ob kliku na spodnji gumb ne zgodi nič, morate dovoliti odpiranje novih zavihkov.",
          button: {
            close: "Zapri",
            continue: "Nadaljuj z reševanjem"
          }
        },
        button_text: {
          "in-progress": "Nadaljuj z reševanjem",
          closed: "Ogled vprašalnika",
          else: "Prični z reševanjem"
        }
      }
    },
    details: {
      header: {
        headline: "Vprašalnik",
        tooltip: "Spremeni pogled",
        duration: "Čas reševanja",
        scope: {
          assessed_person: "Ocenjevana oseba",
          self: "samoocena"
        }
      },
      completed: {
        p1: "Uspešno ste zaključili",
        p2: "Zahvaljujemo se Vam za sodelovanje in želimo uspešen dan."
      },
      content: {
        components: {
          answer: {
            add_row: "Dodaj vrstico",
            sum: "Skupaj",
            cant_edit: "Spreminjanje odgovorov na zaključenih vprašalnikih ni mogoče"
          }
        }
      },
      slides: {
        navigation: {
          back: "Nazaj",
          close: "Zapri",
          end: "Zaključi",
          continue: "Nadaljuj"
        }
      },
      full: {
        content: {
          close: "Zapri vprašalnik",
          end: "Zaključi"
        }
      },
      questionnaire_360: {
        headline: "V vprašalniku boste podali povratno informacijo za osebo {name}. Opredelite vaše razmerje z omenjeno osebo.",
        self: "Samoocena - to sem jaz",
        leader: "Vodja - sem nadrejeni tej osebi",
        coworker: "Sodelavec - sem na istem nivoju tej osebi",
        employee: "Zaposleni - sem podrejeni tej osebi"
      }
    }
  }
}
