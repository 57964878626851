var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'button',{tag:"component",staticClass:"project-button",class:{
        'project-button--green': _vm.color === 'green',
        'project-button--purple': _vm.color === 'purple',
        'project-button--blue': _vm.color === 'blue',
        'project-button--cyan': _vm.color === 'cyan',
        'project-button--pink': _vm.color === 'pink',
        'project-button--yellow': _vm.color === 'yellow',
        'project-button--orange': _vm.color === 'orange',
        'project-button--red': _vm.color === 'red',
        'project-button--small': _vm.small,
        'project-button--empty': _vm.empty,
        'project-button--disabled': _vm.disabled,
    },attrs:{"to":_vm.to ? _vm.to : null,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',[_vm._v(_vm._s(_vm.text)),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }