import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/contracts",
    name: "Contracts",
    meta: { title: "Pogodbe" },
    component: () => import(/* webpackChunkName: "Contracts List" */ "@/Modules/Contract/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/contracts/details/:id",
    name: "Contract Details",
    component: () => import(/* webpackChunkName: "Edit Contract" */ "@/Modules/Contract/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  }
]
