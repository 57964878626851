export default class FilterBuilder {
  filter = []

  builderMethods = [
    "distinct",
    "join",
    "leftJoin",
    "rightJoin",
    "crossJoin",
    "on",
    "orOn",
    "union",
    "where",
    "orWhere",
    "whereBetween",
    "orWhereBetween",
    "whereNotBetween",
    "orWhereNotBetween",
    "whereIn",
    "whereNotIn",
    "orWhereIn",
    "orWhereNotIn",
    "whereNull",
    "whereNotNull",
    "orWhereNull",
    "orWhereNotNull",
    "whereDate",
    "whereMonth",
    "whereDay",
    "whereYear",
    "whereTime",
    "whereColumn",
    "orWhereColumn",
    "whereJsonContains",
    "whereJsonLength",
    "orderBy",
    "latest",
    "oldest",
    "inRandomOrder",
    "reorder",
    "groupBy",
    "having",
    "skip",
    "take",
    "has",
    "orHas",
    "doesntHave",
    "orDoesntHave",
    "withCount",
    "advancedOrWhere",
    "advancedWhere",
    "whereExists",
    "whereHas",
    "whereDoesntHave",
    "advancedJoin",
    "whereHasMorph",
    "whereDoesntHaveMorph",
    "joinSub",
    "search",
    "whereHasMorphIn"
  ]

  constructor (target, filter) {
    this.target = target || this
    this.setFilter(filter || [])
    return this.builderProxy()
  }

  builderProxy () {
    return new Proxy(this.target, {
      get: (target, prop) => {
        if (!this.builderMethods.includes(prop)) {
          return target[prop]
        }
        let vm = this
        return function () {
          let output = [prop]
          for (let i = 0; i < arguments.length; i++) {
            if (typeof arguments[i] !== "function") {
              output.push(arguments[i])
              continue
            }
            let closureBuilder = new vm.constructor()
            arguments[i](closureBuilder)
            output.push(closureBuilder.filter)
          }
          vm.filter.push(output)
          return vm.builderProxy()
        }
      }
    })
  }

  resetFilter () {
    this.filter = []
    return this.builderProxy()
  }

  setFilter (filter) {
    this.filter = filter
    return this.builderProxy()
  }

  getFilter (filter) {
    return filter
  }
}
