import LaravelRepository from "@/providers/api/repositories/LaravelRepository"
import router from "@/providers/router"
export default class Auth extends LaravelRepository {
  route = "auth"
  namespace = "auth"

  state = {
    user: {}
  }

  actions = {
    login: async ({ commit }, params) => {
      const response = await this.request({ namespace: "login" }).post("/auth/login", params)
      if (response.isError) return response
      commit("SET_USER", response.data)
      return true
    },
    logout: async ({ commit }) => {
      const response = await this.request({ namespace: "logout" }).get("/auth/logout")
      if (response.isError) return response
      commit("CLEAR_USER")
      router.push("/login")
    },
    /**
     * Init request is make in src/providers/store.js
     * and sent payload to this action
     * This is a guard helper
     */
    init: async ({ commit }, payload) => {
      const response = await payload
      if (response.isError) return response
      commit("SET_USER", response.data)
    },
    /**
     * Get user payload
     */
    check: async ({ commit }) => {
      const response = await this.request({ namespace: "check" }).get("/auth/user")
      if (response.isError) {
        /**
         * Clear user due to session expiring
         * Behaviour without this: Session expires -> check (due to laravel cookie refresh interval) -> error -> guard redirects to default page for logged in user (Dashboard / Tasks / Home)
         * Behaviour with this: Session expires -> check (due to laravel cookie refresh interval) -> error -> clear user -> guard redirects to default unauthenticated page (Login)
         */
        if (response.error.response.status === 401) {
          commit("CLEAR_USER")
        }
        return response
      }
      commit("SET_USER", response.data)
      // commit("SET_EXPIRATION", promise.data)
    },
    forgotPassword: async ({ commit }, params) => {
      return this.request({ namespace: "forgot-password" }).post("/forgot-password/create", params)
    },
    findPasswordToken: async (ctx, token) => {
      const response = await this.request({ namespace: "forgot-password/find" }).get("/forgot-password/find/" + token)
      return response
    },
    resetPassword: async (ctx, params) => {
      const response = await this.request({ namespace: "forgot-password/reset" }).post("/forgot-password/reset", params)
      return response
    },
    /**
     * Layout schemas
     */
    layoutSchemas: async ({ commit }, key) => {
      const response = await this.request({ namespace: "schemas" }).get("/layouts/" + key + "/schemas")
      return response
    }
    // update: async (params) => {
    //   //               .post
    //   return await this.request("/user/update", params)
    //   // dispatch("user")
    // },
    // changePassword: async (params) => {
    //   //               .post
    //   return await this.request("/user/change-password", params)
    // },
    //
  }
  getters = {
    isAuthenticated: (state) => {
      return Object.keys(state.user).length !== 0
    },
    getUserDetails: (state) => {
      return state.user
    }
  }
  mutations = {
    SET_USER: (state, payload) => {
      if (!payload.candidate) {
        payload.candidate = null
      }
      if (!payload.company) {
        payload.company = null
      }
      if (!payload.employee) {
        payload.employee = null
      }
      if (!payload.companyemployee) {
        payload.companyemployee = null
      }
      state.user = payload
    },
    CLEAR_USER: (state) => {
      state.user = {}
    }
  }
}
