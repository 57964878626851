import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/projects",
    name: "Projects",
    meta: { title: "Projekti" },
    component: () => import(/* webpackChunkName: "Projects List" */ "@/Modules/Project/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/projects/details/:id",
    name: "Project Details",
    component: () => import(/* webpackChunkName: "Project Details" */ "@/Modules/Project/Views/VerticalPanel/VerticalPanel"),
    children: [
      {
        path: "users/:id_user",
        name: "Project User (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
