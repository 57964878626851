import Vue from "vue"

export default {
  created () {
    Vue.prototype.$api = {
      register (resources, componentId = null, namespace = null) {
        Object.keys(resources).forEach(resource => {
          let api = Vue.prototype[namespace || "$api"]
          if (
            api.hasOwnProperty(resource) &&
                  !api[resource].RIds.includes(componentId)
          ) {
            api[resource].RIds.push(componentId)
          } else if (!api.hasOwnProperty(resource)) {
            api[resource] = new resources[resource]()
            api[resource][api[resource].registrationMethod](resource)
            if (componentId) api[resource].RIds.push(componentId)
            // eslint-disable-next-line no-undef, no-console
          } else if (process.env.NODE_ENV !== "production") console.warn(`Trying to register a resource that already exists. resources: ${resource}`)
        })
      },
      unregister (resources, componentId, namespace = null) {
        Object.keys(resources).forEach(resource => {
          let api = Vue.prototype[namespace || "$api"]
          if (
            api.hasOwnProperty(resource) &&
                  api[resource].RIds.length > 0 &&
                  api[resource].RIds.includes(componentId)
          ) {
            if (api[resource].RIds.length > 1) {
              api[resource].RIds.splice(api[resource].RIds.indexOf(componentId), 1)
            } else {
              api[resource][api[resource].unregistrationMethod]()
              delete api[resource]
            }
          } else if (api[resource]) {
            api[resource][api[resource].unregistrationMethod]()
            delete api[resource]
          }
        })
      }
    }
  }
}
