export default {
  confirm: "Confirm",
  cancel: "Prekliči",
  close: "Close",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Izbriši",
  generate: "Generiraj",
  show_details: "Prikaži podrobnosti",
  open: "Odpri",
  edit: "Uredi",
  continue: "Nadaljuj",
  activate: "Aktiviraj",
  deactivate: "Deaktiviraj",
  components: {
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Kontakt",
        remove_button: "Odstrani kontakt",
        add_button: "Dodaj kontakt",
        contact: "Kontakt",
        phone: "Telefonska številka",
        email: "Elektronski naslov",
        any: "Kontakt",
        type: "Tip",
        label: "Ime in priimek"
      }
    },
    multiple_entries_check: {
      label: "Želite vpisati še eno?",
      description: "Po potrditvi bo forma ostala odprta"
    },
    cookies: {
      title: "Tudi mi pečemo piškotke",
      button: "Skrij obvestilo",
      text: "Spletna stran uporablja piškotke za zagotavljanje čim prijetnejše izkušnje. Uporablja samo piškotke, nujne za delovanje strani in ne za namene analitike ter marketinga. Z nadaljevanjem se strinjate z uporabo piškotkov."
    }
  },
  navigation: {
    dashboard: "Odprta opravila",
    projects: "Iskanje in selekcija",
    hranalytics: "HR analitika",
    testings: "Testiranja",
    measures: "Meritve",
    focusgroups: "Fokusne skupine",
    contracts: "Pogodbe",
    employees: "Zaposleni",
    questionnaires: "Vprašalniki",
    settings: "Nastavitve",
    logout: "Odjava"
  },
  active_options: {
    "1": {
      "true": "Aktiven",
      "false": "Neaktiven"
    },
    "2": {
      "true": "Aktivna",
      "false": "Neaktivna"
    }
  },
  static_data: {
    education: {
      "1": "I. Osnovna šola – nedokončana",
      "2": "II. Osnovna šola – dokončana",
      "3": "III. Nižje poklicno izobraževanje",
      "4": "IV. Srednje poklicno izobraževanje",
      "5": "V. Gimnazijsko izobraževanje",
      "6": "VI. Višješolski programi",
      "7": "VII. Univerzitetni programi",
      "8": "VIII. Magisteriji stroke",
      "9": "IX. Magisterij znanosti",
      "10": "X. Doktorati znanosti"
    },
    consent_types: {
      "project": "Projekti",
      "external": "Zunanje testiranje",
      "micro": "Mikro-okolje",
      "client": "Naročniki",
      "free": "Brez",
      "temporary": "Začasno*"
    },
    project_status: {
      "open": "Odprt",
      "closed": "Zaključen"
    },
    testing_status: {
      "open": "Odprt",
      "in-progress": "V izvajanju",
      "closed": "Zaključeno",
      "task": "Čaka na prezvem"
    },
    measure_status: {
      "open": "Odprt",
      "in-progress": "V izvajanju",
      "closed": "Zaključeno"
    },
    contract_status: {
      "preparation": "V pripravi",
      "coordination": "Usklajevanje",
      "signing": "Podpisovanje",
      "signed": "Podpisana"
    },
    contract_types: {
      "isp": "Iskanje in Selekcija",
      "rosl": "ROSL"
    },
    career_level: {
      "beginner": "Začetnik",
      "experienced-worker": "Izkušeni delavec",
      "experienced-expert": "Izkušeni strokovnjak",
      "middle-management": "Srednji management",
      "top-management": "Top management"
    },
    education_level: {
      "secondary": "5 - Srednješolski programi",
      "higher1": "6/1 - Višješolski programi",
      "higher2": "6/2 - Visokošolski programi in 1. bolonjska stopnja",
      "university": "7 - Unverzitetni programi in 2. bolonjska stopnja",
      "master": "8/1 - Magisterij znanosti",
      "doctor": "8/2 - Doktorat znanosti"
    },
    language_level: {
      "basic": "Osnovno",
      "conversational": "Pogovorno",
      "business": "Poslovna uporaba"
    },
    adm: {
      "level": {
        "urgent": "Nujno",
        "desirable": "Zaželeno"
      },
      "languages": {
        "business": "Poslovna uporaba",
        "conversational": "Pogovorno",
        "basic": "Osnovno"
      },
      "job": {
        "form": {
          "fixed": "Določen čas",
          "indefinitely": "Nedoločen čas",
          "mandate": "Mandat",
          "contract": "Pogodbeno delo"
        },
        "reason": {
          "new": "Novo delovno mesto",
          "replacement": "Zamenjava (zaradi odhoda sodelavca, porodniške in drugo)",
          "expansion": "Širitev ekipe"
        },
        "extras": {
          "phone": "Mobilni telefon",
          "car": "Avto",
          "laptop": "Prenosni računalnik",
          "overtime": "Plačevanje nadur",
          "education": "Izobraževanja, usposabljanja",
          "pension": "Dodatno pokojninsko zavarovanje",
          "health": "Dodatno zdravstveno zavarovanje",
          "other": "Nefinančne nagrade"
        },
        "worktime": {
          "fixed": "Fiksni delovni čas",
          "flexible": "Prilagodljiv delovni čas",
          "remote": "Možnost dela od doma"
        }
      }
    }
  }
}
