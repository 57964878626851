import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/testings",
    name: "Testings",
    meta: { title: "Testiranja" },
    component: () => import(/* webpackChunkName: "Testings List" */ "@/Modules/Testing/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/testings/details/:id",
    name: "Testing Details",
    component: () => import(/* webpackChunkName: "Testing Details" */ "@/Modules/Testing/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
