export default {
  views: {
    details: {
      contacts: {
        edit_button: "Edit contacts",
        contacts_popup: {
          save_button: "Save"
        }
      },
      basic: {
        tax_code: "VAT no."
      }
    }
  }
}
