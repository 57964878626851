import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/focus-groups",
    name: "Focus Groups",
    meta: { title: "Fokusne skupine" },
    component: () => import(/* webpackChunkName: "Focus Groups List" */ "@/Modules/FocusGroup/Views/List/List"),
    beforeEnter: ifAuthenticated
  }
]
