export default {
  user: {
    views: {
      list: {
        header: {
          title: "Users",
          search_placeholder: "Search ...",
          add_button: "Add user"
        },
        table: {
          title: "Title",
          created_at: "Created at",
          updated_at: "Updated at",
          author: "Author",
          last_modified: "Last modified",
          active: "Active"
        }
      },
      details: {
        header: {
          back_button: "Users list",
          edit_button: "Edit user",
          toggle: {
            title: "Active",
            activate: "activate",
            deactivate: "deactivate",
            description: "You can {action} user here"
          }
        },
        main: {
          active: "Active",
          inactive: "Inactive",
          overview: {
            author: "Author",
            created_at: "Created at",
            updated_at: "Updated at",
            last_modified: "Last modified"
          }
        },
        resetPassword: {
          title: "Change Password",
          subtitle: "Security and authentication",
          form: {
            currentPassword: "Current password",
            newPassword: "New password",
            changePassword: "Change password",
            passwordRule: "Password has to be at least 6 characters long and contain a letter, a number and a symbol"
          },
          feedback: {
            succcess: "You have successfully changed users password.",
            changeItAgain: "change it again?"
          }
        }
      }
    },
    popup: {
      title: "New user",
      fields: {
        name: "Name and last name",
        username: "Username",
        email: "E-Mail",
        active: {
          description: "Active user will not have to confirm e-mail address.",
          label: "Active user"
        }
      },
      add_button: "Save user",
      update_button: "Save user"
    },
    prompts: {
      delete: {
        title: "Delete user {name}?",
        message: "Are you sure you want to delete user {name}?",
        cancel: "Cancel",
        confirm: "Delete"
      }
    },
    notify: {
      stored: "User {name} was saved",
      updated: "User {name} was updated",
      deleted: "User {name} was deleted",
      not_found: "User not found"
    }
  },
  permissions: {
    title: "Permissions",
    add_button: "Add permissions",
    table: {
      columns: {
        permission: "Permission",
        module: "Module"
      },
      remove_button: "Remove permission"
    },
    empty_notice: "There are no permissions for {name}.",
    add_drawer: {
      title: "Add permissions to {name}",
      search_placeholder: "Search ...",
      permissions_count: "{count} selected",
      table: {
        columns: {
          permission: "Permission",
          module: "Module"
        }
      },
      cancel_button: "Cancel",
      confirm_button: "Add selected permissions",
      notify: {
        success: "{count} permissions added to {name}",
        error: "An error occured while adding permissions to {name}"
      }
    }
  },
  access_drawer: {
    title: "Upravljanje uporabnikov",
    search: "Search ...",
    tag: "Users count: ",
    table: {
      name: "Name and last name"
    },
    cancel_button: "Cancel",
    save_button: "Save"
  },
  one_user_widget: {
    title: "User account",
    email: "E-Mail",
    username: "Username",
    name: "Name and last name",
    edit_button: "Edit",
    new_button: "Create",
    redirect: "View user",
    no_user: "The user does not have a user account. Contact Competo to create an account."
  }
}
