<template>
    <component
        :is="to ? 'router-link' : 'button'"
        :class="{
            'project-button--green': color === 'green',
            'project-button--purple': color === 'purple',
            'project-button--blue': color === 'blue',
            'project-button--cyan': color === 'cyan',
            'project-button--pink': color === 'pink',
            'project-button--yellow': color === 'yellow',
            'project-button--orange': color === 'orange',
            'project-button--red': color === 'red',
            'project-button--small': small,
            'project-button--empty': empty,
            'project-button--disabled': disabled,
        }"
        :to="to ? to : null"
        :disabled="disabled"
        class="project-button"
        @click="$emit('click')"
    >
        <span>{{ text }}<slot/></span>
    </component>
</template>

<script>
export default {
  props: {
    text: { type: String, default: "" },
    color: { type: String, default: "" },
    small: { type: Boolean, default: false },
    empty: { type: Boolean, default: false },
    floating: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    to: { type: String, default: "" }
  }
}
</script>

<style lang="scss">
.project-button {
  padding: 15px 27px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 999px;
  background: darken($blue, 12%);
  color: white;
  line-height: 24px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.18s;
  outline: none;
  span {
    text-decoration: none;
    color: white;
  }
  &:hover {
    cursor: pointer;
    background: $primary;
    box-shadow: 0 5px 15px -5px $primary;
  }
  &:active {
    transform: scale(0.95);
  }
  @media only #{$mediaMobile} {
    font-size: 16px;
    line-height: 20px;
    padding: 12px 23px;
  }
  &--small {
    font-size: 16px;
    padding: 10px 20px;
    font-weight: 400;
    border-width: 1px;
  }
  &--green {
    border-color: $green;
    color: $green;
    &:hover {
      background: $green;
      box-shadow: 0 5px 15px -5px $green;
    }
  }
  &--purple {
    border-color: $purple;
    color: $purple;
    &:hover {
      background: $purple;
      box-shadow: 0 5px 15px -5px $purple;
    }
  }
  &--blue {
    border-color: $blue;
    color: $blue;
    &:hover {
      background: $blue;
      box-shadow: 0 5px 15px -5px $blue;
    }
  }
  &--cyan {
    border-color: $cyan;
    color: $cyan;
    &:hover {
      background: $cyan;
      box-shadow: 0 5px 15px -5px $cyan;
    }
  }
  &--orange {
    border-color: $orange;
    color: $orange;
    &:hover {
      background: $orange;
      box-shadow: 0 5px 15px -5px $orange;
    }
  }
  &--pink {
    border-color: $pink;
    color: $pink;
    &:hover {
      background: $pink;
      box-shadow: 0 5px 15px -5px $pink;
    }
  }
  &--red {
    border-color: $red;
    color: $red;
    &:hover {
      background: $red;
      box-shadow: 0 5px 15px -5px $red;
    }
  }
  &--yellow {
    border-color: $yellow;
    color: $yellow;
    &:hover {
      background: $yellow;
      box-shadow: 0 5px 15px -5px $yellow;
    }
  }
  &--empty,
  &--disabled {
    background: transparent;
    border: solid 1px $primary;
    span {
      transition: all 0.18s;
      color: $primary;
    }
    &:hover {
      span {
        color: white !important;
      }
    }
    &.project-button--green {
      border-color: $green;
      span {
        color: $green;
      }
    }
    &.project-button--pruple {
      border-color: $purple;
      span {
        color: $purple;
      }
    }
    &.project-button--blue {
      border-color: $blue;
      span {
        color: $blue;
      }
    }
    &.project-button--cyan {
      border-color: $cyan;
      span {
        color: $cyan;
      }
    }
    &.project-button--orange {
      border-color: $orange;
      span {
        color: $orange;
      }
    }
    &.project-button--pink {
      border-color: $pink;
      span {
        color: $pink;
      }
    }
    &.project-button--red {
      border-color: $red;
      span {
        color: $red;
      }
    }
    &.project-button--yellow {
      border-color: $yellow;
      span {
        color: $yellow;
      }
    }
  }
  &--disabled {
    pointer-events: none;
  }
}
</style>
