export default {
  confirm: "Confirm",
  cancel: "Cancel",
  close: "Close",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Delete",
  generate: "Generate",
  show_details: "Show details",
  open: "Open",
  edit: "Edit",
  continue: "Continue",
  activate: "Activate",
  deactivate: "Deactivate",
  components: {
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Contact",
        remove_button: "Remove contact",
        add_button: "Add contact",
        contact: "Contact",
        phone: "Phone",
        email: "E-mail",
        any: "Contact",
        type: "Type",
        label: "First and last name"
      }
    },
    multiple_entries_check: {
      label: "Would you like to enter another one?",
      description: "The form will remain open even after pressing confirm button"
    },
    cookies: {
      title: "We bake cookies too",
      button: "I understand",
      text: "This website uses cookies to provide the best experience. It only uses cookies that are necessary for the site to function and not for analytics and marketing purposes. By continuing you agree to the usage of cookies."
    }
  },
  navigation: {
    dashboard: "Open tasks",
    projects: "Search and selection",
    hranalytics: "HR analitics",
    testings: "Tests",
    measures: "Measurements",
    focusgroups: "Focus groups",
    contracts: "Contracts",
    employees: "Employees",
    questionnaires: "Questionnaires",
    settings: "Settings",
    logout: "Logout"
  },
  active_options: {
    "1": {
      "true": "Active",
      "false": "Inactive"
    },
    "2": {
      "true": "Active",
      "false": "Inactive"
    }
  },
  static_data: {
    education: {
      "1": "I. Primary school – unfinished",
      "2": "II. Primary school – completed",
      "3": "III. Lower vocational education",
      "4": "IV. High school level vocational education ",
      "5": "V. High school education",
      "6": "VI. Higher education programs",
      "7": "VII. University degree programs",
      "8": "VIII. Master's degree",
      "9": "IX. Researched based Master's degree",
      "10": "X. Ph.D"
    },
    consent_types: {
      "project": "Projects",
      "external": "External testing",
      "micro": "Micro-environment",
      "client": "Clients",
      "free": "Free",
      "temporary": "Temporary*"
    },
    project_status: {
      "open": "Open",
      "closed": "Closed"
    },
    testing_status: {
      "open": "Open",
      "in-progress": "In progress",
      "closed": "Closed",
      "task": "Waiting"
    },
    measure_status: {
      "open": "Open",
      "in-progress": "In progress",
      "closed": "Closed"
    },
    contract_status: {
      "preparation": "In preparation",
      "coordination": "Coordinating agreement",
      "signing": "Put in signing",
      "signed": "Signed"
    },
    contract_types: {
      "isp": "Search and selection",
      "rosl": "ROSL"
    },
    career_level: {
      "beginner": "Beginner",
      "experienced-worker": "Experienced worker",
      "experienced-expert": "Experienced expert",
      "middle-management": "Middle management",
      "top-management": "Top management"
    },
    education_level: {
      "secondary": "5 – High school programs",
      "higher1": "6/1 - Higher education programs",
      "higher2": "6/2 – Vocational university programs and  Bachelor's degree - 1st Bologne level",
      "university": "7 – University degree programs and 2nd Bologne Master's degree ",
      "master": "8/1 – Master's degree – research based",
      "doctor": "8/2 - Ph.D"
    },
    language_level: {
      "basic": "Basic",
      "conversational": "Conversational",
      "business": "Business use"
    },
    adm: {
      "level": {
        "urgent": "Urgent",
        "desirable": "Desirable"
      },
      "languages": {
        "business": "Business use",
        "conversational": "Conversational",
        "basic": "Basic"
      },
      "job": {
        "form": {
          "fixed": "Fixed/defined period",
          "indefinitely": "Indefinite period",
          "mandate": "Mandate",
          "contract": "Contract work"
        },
        "reason": {
          "new": "New position",
          "replacement": "Replacement (e.g. due to replacement, maternity leave or other)",
          "expansion": "Team expansion"
        },
        "extras": {
          "phone": "Cell-phone",
          "car": "Car",
          "laptop": "Laptop",
          "overtime": "Overtime payment",
          "education": "Trainings, seminars",
          "pension": "Extra pension insurance",
          "health": "Extra health insurance",
          "other": "Nonfinancial bonuses"
        },
        "worktime": {
          "fixed": "Fixed",
          "flexible": "Flexible",
          "remote": "Option to work from home"
        }
      }
    }
  }
}
