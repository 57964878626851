import Vue from "vue"

export default {
  data () {
    return {
      title: { main: "Competo Partner", view: null, full: null }
    }
  },
  computed: {
    routeTitle () {
      if (this.$route.meta && this.$route.meta.title) {
        if (Vue.i18n.locale() === "sl-SI") {
          return this.$route.meta.title
        } else if (Vue.i18n.locale() === "en-EN") {
          let translations = {
            "Page not found": "Page not found",
            "HR analitika": "HR analitics",
            "Log In": "Log In",
            "Reset Password": "Reset Password",
            "New Password": "New Password",
            "Zaposleni": "Employees",
            "Nov zaposleni": "New employee",
            "Pogodbe": "Contracts",
            "Fokusne skupine": "Focus groups",
            "Informativna ponudba": "Informative quote",
            "Meritve": "Measurements",
            "Projekti": "Projects",
            "Vprašalniki": "Questionnaires",
            "Vprašalnik": "Questionnaire",
            "Nastavitve": "Settings",
            "Pregled": "Dashboard",
            "Testiranja": "Tests"
          }

          if (translations[this.$route.meta.title]) {
            return translations[this.$route.meta.title]
          }
        }
      }
      return ""
    }
  },
  watch: {
    routeTitle (to) {
      if (to || to === "") this.setTitle(to)
    }
  },
  methods: {
    setTitle (title) {
      this.title.view = title
      this.title.full = (title ? title + " | " : "") + this.title.main
      document.title = this.title.full
    }
  }
}
