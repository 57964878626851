export default {
  views: {
    list: {
      header: {
        title: "Measurements",
        search_placeholder: "Search ..."
      },
      table: {
        title: "Title",
        status: "Status",
        created_at: "Created",
        updated_at: "Updated",
        type: "Measurement type"
      },
      type: {
        climate: "Climate",
        culture: "Culture",
        commitment: "Engagement",
        "360": "360"
      }
    },
    details: {
      header: {
        back_button: "To the list of measurements"
      },
      basic: {
        project: "Project",
        measure_type: "Measurement type",
        measure_360: "Evaluation 360",
        questionnaire: "Attached questionnaire",
        deadline_at: "End measurement",
        created_at: "Created",
        updated_at: "Updated",
        comment: "Remark",
        type: {
          climate: "Climate",
          culture: "Culture",
          commitment: "Engagement",
          "360": "360"
        }
      },
      stats: {
        status: "Measurement status",
        questionnaires: "Number of received questionnaires",
        measure_closed: "Measurement ended",
        finished: "Finished"
      }
    }
  }
}
