export default {
  views: {
    list: {
      header: {
        title: "Focus groups",
        search_placeholder: "Search ..."
      },
      table: {
        title: "Title",
        created_at: "Created"
      }
    }
  }
}
