/**
 * FontAwesome icons
 */

import { library } from "@fortawesome/fontawesome-svg-core"

import { fal } from "@fortawesome/pro-light-svg-icons"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import { fad } from "@fortawesome/pro-duotone-svg-icons"

library.add(fal, far, fas, fad)
