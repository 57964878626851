// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  // {
  //   path: "/administration/document-templates",
  //   name: "Document Templates",
  //   meta: { title: "Document Templates" },
  //   component: () => import(/* webpackChunkName: "Document Templates List" */ "@/Modules/DocumentTemplate/Views/List"),
  //   children: [
  //     {
  //       path: "new",
  //       name: "New Document Template",
  //       meta: { title: "New Document Template" }
  //     },
  //     {
  //       path: "edit/:id",
  //       name: "Edit Document Template (List)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // },
  // {
  //   path: "/administration/document-templates/details/:id",
  //   name: "Document Template Details",
  //   component: () => import(/* webpackChunkName: "Document Template Details" */ "@/Modules/DocumentTemplate/Views/Edit"),
  //   children: [
  //     {
  //       path: "edit",
  //       name: "Edit Document Template (Details)"
  //     }
  //   ],
  //   beforeEnter: ifAuthenticated
  // }
]
