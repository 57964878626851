export default {
  view: {
    header: {
      title: "HR analitics",
      tabs: {
        questionnaire: "Questionnaires",
        company: "HR Maturity"
      }
    },
    questionnaire: {
      select: {
        industry: "Industry",
        company: "Company",
        my_company: "My company",
        period: {
          label: "Time period",
          placeholder: "Pick time period"
        },
        type: "Group",
        age: {
          min: "Age - MIN",
          max: "Age - MAX"
        },
        gender: "Sex",
        career: "Career level",
        education: "Level of education"
      },
      options: {
        industry: "All industries",
        company: "All companies",
        career: "All",
        education: "All",
        gender: {
          both: "All",
          male: "Male",
          female: "Female"
        }
      },
      loading: "Loading data",
      average: "Average score",
      average_column: "AVERAGE",
      competence: "Competences"
    },
    company: {
      details: {
        header: {
          back_button: "To the list of companies"
        },
        tabs: {
          form: "Form",
          charts: "Graphs"
        },
        fields: {
          "condition": "Current state assesment",
          "desired-condition": "Desired state",
          "strategic-significance": "Strategic importance",
          "operational-significance": "Operational importance",
          "development": "Need for development considering strategy in next two years",
          "future": "Foreseen for future years",
          "orientation": {
            label: "Focus",
            options: {
              "accelerated-development": "Accelerated development",
              "develop": "Development",
              "maintain": "Maintain",
              "abandon": "Abandon"
            }
          }
        },
        form: {
          save_button: "Shrani",
          helper: "GRADING SCALE</br></br>CURRENT STATE in DESIRED STATE</br>5: perfect</br>4: very good </br>3: good</br>2: bad</br>1: very bad</br></br>STRATEGIC IMPORTANCE and OPERATIONAL IMPORTANCE</br>5: crucial</br>4: very important</br>3: important</br>2: less important</br>1: unimportant"
        },
        table: {
          set: "Set",
          condition: "Current state",
          "desired-condition": "Desired state"
        },
        charts: {
          condition: "Current state",
          "desired-condition": "Desired state"
        },
        set: "Set",
        field_of_work: "Field of work"
      }
    }
  }
}
