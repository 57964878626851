export default [
  {
    path: "/offers",
    name: "Offers",
    meta: { title: "Informativna ponudba" },
    component: () => import(/* webpackChunkName: "Questionnaire Details" */ "@/Modules/InformativeOffer/Views/Form/Form"),
    children: [
      {
        path: "complete",
        name: "Offers Completed",
        meta: { title: "Informativna ponudba" }
      }
    ]
  }
]
